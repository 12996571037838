<script setup>
import {Head, Link} from '@inertiajs/vue3';
import {defineAsyncComponent, onMounted, onUnmounted, ref} from "vue";
import {PlayCircleIcon, StarIcon} from '@heroicons/vue/20/solid'
import ShippingPriceCalculatorV2 from "@/Components/ShippingPriceCalculatorV2.vue";
import Block from "@/Components/Block.vue";
import ScrollObserver from "@/Components/ScrollObserver.vue"
import ScrollTriggerGroup from "@/Components/ScrollTriggerGroup.vue"
import ScrollTrigger from "@/Components/ScrollTrigger.vue"
import ScrollReactorGroup from "@/Components/ScrollReactorGroup.vue"
import ScrollReactor from "@/Components/ScrollReactor.vue"
import Block2 from "@/Components/Block2.vue";
import Block3 from "@/Components/Block3.vue";
import Block4 from "@/Components/Block4.vue";
import Block5 from "@/Components/Block5.vue";
import Block6 from "@/Components/Block6.vue";
import SamosSpotlightBtn from "@/Components/SamosSpotlightBtn.vue";
import SamosSpotlightLink from "@/Components/SamosSpotlightLink.vue";

const HomeVideo = defineAsyncComponent(() =>
    import('@/Components/HomeVideo.vue')
);
const ContactUsDialogue = defineAsyncComponent(() =>
    import('@/Components/ContactUsDialogue.vue')
);

const props = defineProps({
    testimonials: {
        type: Array,
    },
    blog_posts: {
        type: Array
    },
    og_image: String,
});

let isMobile = ref();
const isServer = typeof document === 'undefined';
let showVideo = ref(false);
let loadHomeVideo = ref(false);
let observer = null;

onMounted(() => {
    isMobile.value = window.innerWidth <= 640;

    if (window.innerWidth >= 1024) {
        calculateLogoPositioning();
        window.addEventListener('scroll', doOnScroll);
    } else {
        document.getElementById('page-logo').classList.add('hidden');
    }

    let video = document.querySelector('.video');
    if (video && !isServer) {
        observer.observe(video);
    }

});

onUnmounted(() => {
    window.removeEventListener('scroll', doOnScroll);

    if (headerLogo) {
        headerLogo.classList.remove('hidden');
        header.classList.add('bg-white');
    }

    if (!isServer) {
        observer.disconnect();
    }

});

//if the video comes into view, showVideo is set to true
if (!isServer) {
    observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                loadHomeVideo.value = true;
                if (!showVideo.value) {
                    setTimeout(() => {
                        showVideo.value = true;
                    }, 1000);
                }
            }
        });
    }, {threshold: 0.5});
}


let lastKnownScrollPosition = 0;
let ticking = false;
let openContactUsDialogue = ref(false);

function openContactUsDialogueFn() {
    openContactUsDialogue.value = true;
    gtag('event', 'contact_us_opened', {
        'event_category': 'engagement',
        'event_label': 'contact_us_opened'
    });
}

let doOnScroll = function () {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
        window.requestAnimationFrame(function () {
            animateLogo(lastKnownScrollPosition);
            ticking = false;
        });

        ticking = true;
    }
}

let headerLogoTop = null;
let pageLogoTop = null;
let headerLogo = null;
let pageLogo = null;
let header = null;

function animateLogo(scrollPos) {
    //scale the logo from 1.5 to 1 based on the position of the logos and the scroll position
    let initial_distance = pageLogoTop - headerLogoTop;
    let distance = initial_distance - scrollPos;
    let percentage = (distance / initial_distance);

    if (percentage >= 0) {
        pageLogo.classList.remove('hidden');
        headerLogo.classList.add('hidden');
        header.classList.remove('bg-white');
        let scale = 1 + (percentage * 0.5);

        pageLogo.style.transform = `scale(${scale})`;
    }

    if (percentage <= 0) {
        pageLogo.classList.add('hidden');
        headerLogo.classList.remove('hidden');
        header.classList.add('bg-white');
    }

}

function calculateLogoPositioning() {
    headerLogo = document.getElementById('header-logo');
    pageLogo = document.getElementById('page-logo');
    header = document.getElementById('header');

//get the top position of both logos
    headerLogoTop = headerLogo.getBoundingClientRect().top;
    pageLogoTop = pageLogo.getBoundingClientRect().top;

    animateLogo(0);
}

const shipping_items = [
    {
        title: "1. Send us your orders",
        content: "Whether through our online integrations or manual entry, you can get your customer order details to us quickly and easily.",
        component: Block,
    },
    {
        title: "2. We handle the logistics",
        content: "We generate shipping labels, provide tracking numbers and manage the end-to-end delivery process for you.",
        component: Block2,
    },
    {
        title: "3. Customers receive their orders",
        content: "Your parcels are customs cleared and delivered to your customers within 2-6 working days, completely hassle free.",
        component: Block3,
    },
];

const return_items = [
    {
        title: "1. Customer logs return",
        content: "Your customer enters their number into the SAMOS Returns portal. They can select the product they wish to return, their preferred returns method (QR code or printed label) and a drop-off point of their choice.",
        component: Block4,
    },
    {
        title: "2. Customer visits drop-off point",
        content: "Once they’ve securely repackaged their order, your customer can take it to their elected drop-off point.",
        component: Block5,
    },
    {
        title: "3. We take care of the rest",
        content: "The parcel will be delivered back to SAMOS where we will check it (if requested), customs clear it, and then deliver it back to you. We’ll even notify the customer that it has been received.",
        component: Block6,
    },
];

const tabs = ref([
    {name: 'Shipping', current: true},
    {name: 'Returns', current: false},
]);
</script>

<template>
    <Head>
        <title>E-commerce delivery made easy</title>
        <meta content="E-commerce delivery made easy" head-key="og:title" property="og:title">
        <meta content="https://samos-e.com" head-key="og:url" property="og:url">
        <meta content="website" head-key="og:type" property="og:type">
        <meta
            content="Ship your online products your way, with SAMOS shipping. Track your order, get help with delivery and set up an integrated shopping platform."
            head-key="description"
            name="description"/>
        <meta
            content="Ship your online products your way, with SAMOS shipping. Track your order, get help with delivery and set up an integrated shopping platform."
            head-key="og:description"
            property="og:description">
        <meta :content="og_image" head-key="og:image" property="og:image">
        <link head-key="canonical" href="https://samos-e.com/" rel="canonical">
    </Head>


    <div class="relative isolate">
        <svg
            aria-hidden="true"
            class="absolute inset-0 -z-10 h-full w-full stroke-electric-violet-100 [mask-image:radial-gradient(white,transparent_70%)]">
            <defs>
                <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" height="200" patternUnits="userSpaceOnUse" width="200"
                         x="50%"
                         y="-1">
                    <path d="M100 200V.5M.5 .5H200" fill="none"/>
                </pattern>
            </defs>
            <svg class="overflow-visible fill-gray-50" x="50%" y="-1">
                <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                      stroke-width="0"/>
            </svg>
            <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" height="100%" stroke-width="0" width="100%"/>
        </svg>
        <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
            <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">

                <div class="h-8 relative">
                    <svg id="page-logo" class="h-8 w-auto absolute z-10"
                         style="transform-origin: bottom left;"
                         viewBox="0 0 185 40">
                        <g fill="#000">
                            <path
                                d="m16.8 9.6c-1-2.8-2.9-3.4-4.8-3.4-2.8 0-4.5 1.7-4.5 4 0 2.1 1.8 3.1 3.7 3.7l4.4 1.5c5.8 1.8 7.4 5.5 7.4 8.8 0 5.9-5.1 9.4-11.1 9.4-5.4 0-10.5-2.8-11.9-8.2l5.3-1.3c.7 2.5 3.5 4.3 6.6 4.3s5.3-1.8 5.3-4.2c0-2.1-1.7-3.2-3.9-3.9l-4.1-1.4c-4.9-1.5-7.6-4.2-7.6-8.5.1-5.4 4.1-9.4 10.2-9.4 4.3 0 8.5 1.8 10.3 7.4z"></path>
                            <path
                                d="m43.1 1c4.1 0 8.2 1.4 11.1 5.7v-5h6v31.3h-6v-5c-2.9 4.2-7 5.7-11.1 5.7-9.2 0-15.2-7.4-15.2-16.3s6-16.4 15.2-16.4zm.8 5.3c-6.4 0-10 5-10 10.9s3.6 10.9 10 10.9c6.2 0 10.4-4.6 10.5-10.9-.1-6.2-4.3-10.9-10.5-10.9z"></path>
                            <path
                                d="m89.9 15.3c0-6.6-3-8.9-6.3-8.9s-8.7 2.2-8.7 12.1v14.5h-6v-31.3h6v6.3c1.3-4.6 5.4-7 9.9-7 2.9 0 7.4 1 9.8 6.6 2.5-5.5 6.7-6.6 10.4-6.6 7.2 0 11.9 4.2 11.9 14.3v17.6h-6v-17.6c0-6.6-3-8.9-6.3-8.9-2.9 0-8.4 1.8-8.7 11.3v15.2h-6z"></path>
                            <path
                                d="m178.8 9.6c-1-2.8-2.9-3.4-4.8-3.4-2.8 0-4.5 1.7-4.5 4 0 2.1 1.8 3.1 3.7 3.7l4.4 1.5c5.8 1.8 7.4 5.5 7.4 8.8 0 5.9-5.1 9.4-11.1 9.4-5.4 0-10.4-2.8-11.9-8.2l5.3-1.3c.7 2.5 3.5 4.3 6.6 4.3s5.3-1.8 5.3-4.2c0-2.1-1.7-3.2-3.9-3.9l-4.1-1.4c-4.9-1.5-7.5-4.2-7.5-8.5 0-5.3 4.1-9.3 10.1-9.3 4.3 0 8.5 1.8 10.3 7.4z"></path>
                            <path
                                d="m140.4 0c-9.3 0-16.9 7.7-16.9 17.1v1c0 .2 0 .5.1.7 1.2 13.2 16.8 21.1 16.8 21.1s15.8-8 16.9-21.1c0-.2 0-.5 0-.7 0-.3 0-.7 0-1 0-9.4-7.6-17.1-16.9-17.1zm10.7 17.2c0 6.3-4.8 11.4-10.7 11.4s-10.6-5.1-10.6-11.4c0-6.4 4.8-11.5 10.6-11.5 5.9-.1 10.7 5.1 10.7 11.5z"></path>
                        </g>
                    </svg>
                </div>

                <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-electric-violet-700 sm:text-6xl">Simple shipping to the EU and beyond</h1>
                <p class="mt-6 text-lg leading-8 text-electric-violet-600">International one stop shop <span
                    class="sr-only">(<abbr
                    title="International one stop shop">IOSS</abbr>)</span> for online retailers, big and small. Ensure your parcels sail through customs and reach their destinations on time.
                </p>
                <ol class="mt-6 text-lg leading-8 text-electric-violet-600 list-disc list-inside">
                    <li>No hidden fees or charges to your customers</li>
                    <li>Ship with or without <abbr>IOSS</abbr> to the EU</li>
                    <li>Delivered within 2-6 working days</li>
                </ol>
                <div class="mt-10 flex items-center gap-x-6 mb-16">

                    <p class="text-electric-violet-600">Is SAMOS for me?</p>

                    <button
                        class="text-sm leading-6 text-electric-violet-600 border border-electric-violet-600 px-6 py-2.5 rounded-full hover:bg-gray-50 uppercase"
                        @click="openContactUsDialogueFn()">
                        Talk to us
                    </button>
                    <contact-us-dialogue v-if="openContactUsDialogue" v-model="openContactUsDialogue"></contact-us-dialogue>
                </div>
            </div>
            <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                <shipping-price-calculator-v2 id="get-a-quote"></shipping-price-calculator-v2>
            </div>
        </div>
    </div>

    <section class="overflow-hidden mb-16">
        <div
            class="flex flex-col max-w-7xl items-center justify-between pt-6 xl:px-8 xl:mx-auto">
            <div class="w-full">
                <h3 class="text-center text-3xl mb-4 px-3">They trust us to ship around the world</h3>

                <div class="py-12 flex justify-center">
                    <div class="mx-auto">
                        <div
                            class="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0">
                            <Link href="/customer-stories/tic-cc">
                                <img alt="Tic CC logo"
                                     class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                                     height="208" loading="lazy"
                                     src="../../img/customer-stories/tic-cc/tic-cc-logo.svg"
                                     width="208"/>
                            </Link>
                            <Link href="/customer-stories/the-grooming-clinic">
                                <img alt="The grooming clinic logo" class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                                     height="56" loading="lazy"
                                     src="../../img/customer-stories/tgc/tgc-logo.svg"
                                     width="128"/>
                            </Link>
                            <Link href="/customer-stories/genuine-motors">
                                <img alt="Genuine motors logo" class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                                     height="200" loading="lazy"
                                     src="../../img/customer-stories/gm/gm-logo.svg"
                                     width="144"/>
                            </Link>
                            <Link href="/customer-stories/bofi-racing">
                                <img alt="BOFI racing logo"
                                     class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                                     height="150" loading="lazy"
                                     src="../../img/customer-stories/bofi/bofi-logo.svg"
                                     width="131"/>
                            </Link>
                            <Link href="/customer-stories/camtraptions">
                                <img alt="Camtraptions logo"
                                     class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                                     height="208" loading="lazy"
                                     src="../../img/customer-stories/camtraptions/camtraptions-logo.svg"
                                     width="208"/>
                            </Link>
                            <img alt="Alexandria UK logo"
                                 class="col-span-1 max-h-12 w-full object-contain sm:col-start-auto"
                                 height="250"
                                 loading="lazy"
                                 src="../../img/customer-stories/alexandriauk/alexandriauk-logo.svg"
                                 width="177"/>
                        </div>
                    </div>
                </div>
                <div class="mt-4 flex justify-center">
                    <p class="relative rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/5">
            <span
                class="hidden md:inline">Hundreds of companies use SAMOS to ship their products all around the globe</span>
                        <Link class="font-semibold text-electric-violet-600" href="/customer-stories"><span aria-hidden="true"
                                                                                                            class="absolute inset-0"/> Read our customer stories
                        </Link>
                    </p>
                </div>

            </div>
        </div>
    </section>

    <section class="overflow-hidden">
        <div class="flex flex-col max-w-7xl items-center justify-between xl:px-8 xl:mx-auto video">
            <div
                class="text-electric-violet-600 w-full pb-28">

                <div class="gap-12 flex-col md:flex-row flex">

                    <div v-if="!showVideo" class="relative cursor-pointer md:w-3/4 rounded-2xl overflow-hidden">
                        <div class="absolute w-full h-full flex justify-center items-center" @click="showVideo=true">
                            <span class="bg-white rounded-full">
                                <PlayCircleIcon class="h-16 w-16 text-black"/>
                            </span>
                        </div>
                        <picture>
                            <source
                                sizes="(max-width: 1572px) 100vw, 1572px"
                                srcset="../../img/video-poster/video-poster_txdbtv_c_scale-w_320.avif 320w, ../../img/video-poster/video-poster_txdbtv_c_scale-w_1369.avif 1369w, ../../img/video-poster/video-poster_txdbtv_c_scale-w_1572.avif 1572w"
                                type="image/avif"
                            >
                            <source
                                sizes="(max-width: 1572px) 100vw, 1572px"
                                srcset="../../img/video-poster/video-poster_grouzf_c_scale-w_320.webp 320w, ../../img/video-poster/video-poster_grouzf_c_scale-w_904.webp 904w, ../../img/video-poster/video-poster_grouzf_c_scale-w_1363.webp 1363w, ../../img/video-poster/video-poster_grouzf_c_scale-w_1572.webp 1572w"
                                type="image/webp"
                            >
                            <img alt="SAMOS Video Poster" class="aspect-video w-full" loading="lazy"
                                 src="../../img/video-poster/video-poster.jpg">
                        </picture>
                    </div>

                    <div v-if="showVideo && loadHomeVideo" class="md:w-3/4 rounded-2xl overflow-hidde relative aspect-video">
                        <HomeVideo></HomeVideo>
                    </div>

                    <div class="md:w-1/4 flex flex-col gap-4 justify-center items-center px-6 md:pl-0 md:pr-6">
                        <p>SAMOS integrates with the most popular e-commerce platforms to help you improve your delivery process, maximise sales and provide a more seamless shopping experience.</p>
                        <samos-spotlight-link class="text-center" href="/services/integrations">View Integrations</samos-spotlight-link>
                    </div>
                </div>

            </div>
        </div>
    </section>


    <section class="overflow-hidden -mt-9">
        <div class="flex flex-col max-w-7xl items-center justify-between xl:px-8 xl:mx-auto video">
            <div
                class="bg-pot-pourri-100 text-electric-violet-600 w-full rounded-t-5xl pt-16 pb-28 px-6 xl:px-12 border border-electric-violet-500">

                <h3 class="text-electric-violet-600 text-4xl text-center font-medium leading-tight">Why SAMOS?</h3>

                <dl class="grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-10 md:gap-y-20 mt-16 max-w-4xl mx-auto">
                    <div class="flex flex-col">
                        <dt class="text-base/7 font-semibold flex items-center gap-x-16 justify-between">
                            Reliable and consistent international delivery
                            <div>
                                <svg class="max-w-20 max-h-28" height="111.09" viewBox="0 0 139.42 111.09" width="139.42" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" stroke="#a3674d" stroke-width="4" transform="translate(-9.29 -6.96)">
                                        <path d="m108.76 27.38a44.578 44.578 0 1 1 -13.13-.02" stroke-miterlimit="10"/>
                                        <path d="m117.51 44.92-14.28 26.85h-14.61" stroke-linecap="round" stroke-linejoin="round"/>
                                        <g stroke-miterlimit="10">
                                            <path d="m70.63 39.94 5.51 5.51"/>
                                            <path d="m133.59 39.89-5.74 5.92"/>
                                            <path d="m146.71 71.47h-8.88"/>
                                            <path d="m57.56 71.47h8.6"/>
                                            <path d="m76.86 96.8-6.22 6.21"/>
                                            <path d="m102.13 106.72v9.32"/>
                                            <path d="m126.13 95.39 7.55 7.56"/>
                                            <path d="m35.37 57.11h15.32" stroke-linecap="round"/>
                                            <path d="m11.29 68.06h33.65" stroke-linecap="round"/>
                                            <path d="m32.49 79h15.33" stroke-linecap="round"/>
                                            <rect height="13.2" rx="2.23" width="13.2" x="95.57" y="17.82"/>
                                            <path d="m96.44 8.96h11.38" stroke-linecap="round"/>
                                            <path d="m102.17 17.82-.04-8.86"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </dt>
                        <dd class="mt-1 flex flex-col text-base/7 font-light">
                            We personally handle your deliveries and work with trusted local partners to make the final mile without delay.
                        </dd>

                    </div>

                    <div class="flex flex-col">
                        <dt class="text-base/7 font-semibold flex items-center gap-x-16 justify-between">
                            Clear and transparent pricing
                            <div>
                                <svg class="max-w-20 max-h-28" height="111.09" viewBox="0 0 111.089 111.09" width="111.089" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" stroke="#a3674d" stroke-linecap="round" stroke-linejoin="round" stroke-width="4">
                                        <path d="m80.072 49.247s2.108-19.707-17.193-19.707c-12.8 0-16.229 11.522-16.229 19.216v43.081h31.409c3.733-.435 6.021-1.815 6.484-4.1" transform="translate(-7.372 -5.148)"/>
                                        <path d="m55.951 62.312h-26.484"/>
                                        <ellipse cx="55.545" cy="55.545" rx="53.545" ry="53.545"/>
                                        <path d="m18.087 17.293 74.906 76.504"/>
                                    </g>
                                </svg>

                            </div>
                        </dt>
                        <dd class="mt-1 flex flex-col text-base/7 font-light">
                            It’s our policy to ensure our customers avoid unexpected fees. We aim to always provide a clear or fixed price upfront.
                        </dd>

                    </div>

                    <div class="flex flex-col">
                        <dt class="text-base/7 font-semibold flex items-center gap-x-16 justify-between">
                            All cross-border complexities managed
                            <div>
                                <svg class="max-w-20 max-h-28" height="96.36" viewBox="0 0 126.96 96.36" width="126.96" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" stroke="#a3674d" stroke-miterlimit="10" stroke-width="4">
                                        <circle cx="12.48" cy="83.88" r="10.48"/>
                                        <circle cx="45.75" cy="37.06" r="10.48"/>
                                        <circle cx="82.83" cy="67.53" r="10.48"/>
                                        <circle cx="114.48" cy="12.48" r="10.48"/>
                                        <path d="m39.45 45.41-21.52 29.54"/>
                                        <path d="m54.11 43.35 20.36 16.83"/>
                                        <path d="m109.08 21.45-20.7 37.2"/>
                                    </g>
                                </svg>

                            </div>
                        </dt>
                        <dd class="mt-1 flex flex-col text-base/7 font-light">
                            We handle everything, from import duties to clearance, to give you peace of mind and your customer a consistently great shopping experience.
                        </dd>

                    </div>

                    <div class="flex flex-col">
                        <dt class="text-base/7 font-semibold flex items-center gap-x-16 justify-between">
                            Insightful and accessible customer service
                            <div>
                                <svg class="max-w-20 max-h-20" height="108.427" viewBox="0 0 122.029 108.427" width="122.029" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Samos_website_2023_icons" data-name="Samos website 2023 icons" transform="translate(-11.49 -22.69)">
                                        <path id="Path_78"
                                              d="M13.5,41.833V86.394a17.117,17.117,0,0,0,17.119,17.119H84.8a15.257,15.257,0,0,1,10.78,4.476l18.314,18.3V109.161a5.793,5.793,0,0,1,5.765-5.812h.012a11.854,11.854,0,0,0,11.846-11.823V41.809A17.127,17.127,0,0,0,114.388,24.69H30.609A17.125,17.125,0,0,0,13.49,41.809Z"
                                              data-name="Path 78"
                                              fill="none" stroke="#a3674d" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4" transform="translate(0 0)"/>
                                        <line id="Line_31" data-name="Line 31" fill="none" stroke="#a3674d" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4" transform="translate(56.001 81.777)" x1="33.008"/>
                                        <line id="Line_32" data-name="Line 32" fill="none" stroke="#a3674d" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4" transform="translate(39.503 65.759)" x1="66.004"/>
                                        <line id="Line_33" data-name="Line 33" fill="none" stroke="#a3674d" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4" transform="translate(39.503 49.742)" x1="66.004"/>
                                    </g>
                                </svg>
                            </div>
                        </dt>
                        <dd class="mt-1 flex flex-col text-base/7 font-light">
                            You will be supported by a delivery expert with in-depth operational knowledge and decades of experience, and with whom you can easily make contact during normal working hours.
                        </dd>

                    </div>
                </dl>

            </div>
        </div>
    </section>


    <section id="how-it-works" class="relative pb-16 lg:pb-32 xl:pb-48 -mt-10 overflow-hidden max-w-7xl mx-auto xl:px-8">
        <div
            class="absolute bg-cape-palliser-100 w-full xl:w-[calc(100%-4rem)] h-full overflow-hidden rounded-t-5xl border border-b-0 border-electric-violet-500">
        </div>
        <div class="max-w-7xl mx-auto relative">

            <h3 class="text-electric-violet-600 text-4xl text-center font-medium leading-tight pt-20">How it works</h3>

            <div>

                <div class="px-6 sm:px-12 lg:px-24 pt-12">

                    <div class="flex justify-center mb-12">
                        <div class="inline-block">
                            <nav aria-label="Tabs" class="isolate flex divide-x divide-gray-200 rounded-lg shadow">
                                <button v-for="(tab, tabIdx) in tabs" :key="tab.name" :aria-current="tab.current ? 'page' : undefined"
                                        :class="[tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white px-8 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10']"
                                        @click="tabs.forEach(tab => tab.current = false); tab.current = true"
                                >
                                    <span>{{ tab.name }}</span>
                                    <span :class="[tab.current ? 'bg-electric-violet-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" aria-hidden="true"/>
                                </button>
                            </nav>
                        </div>
                    </div>

                    <ScrollObserver v-if="tabs[0].current" class="relative mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8 lg:gap-16">
                        <ScrollTriggerGroup class="">
                            <ScrollTrigger
                                v-for="(item, index) in shipping_items"
                                :key="index"
                                v-slot="{ isActive }"
                                class="py-8">
                                <div :class="[isActive ? 'text-electric-violet-600' : 'text-electric-violet-600/30 contrast-more:text-electric-violet-600']" class="transition">
                                    <div class="font-display text-2xl font-medium leading-snug">
                                        {{ item.title }}
                                    </div>

                                    <div class="mt-4 text-base opacity-75">
                                        {{ item.content }}
                                    </div>
                                </div>
                            </ScrollTrigger>
                        </ScrollTriggerGroup>

                        <ScrollReactorGroup class="sticky top-0 sm:flex items-center justify-center hidden">
                            <ScrollReactor
                                v-for="(item, index) in shipping_items"
                                :key="index"
                                v-slot="{ show, faded }"
                                class="absolute inset-0 flex items-center justify-center">
                                <component :is="item.component" :faded="faded" :show="show"/>
                            </ScrollReactor>
                        </ScrollReactorGroup>
                    </ScrollObserver>

                    <ScrollObserver v-if="tabs[1].current" class="relative mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8 lg:gap-16">
                        <ScrollTriggerGroup class="">
                            <ScrollTrigger
                                v-for="(item, index) in return_items"
                                :key="index"
                                v-slot="{ isActive }"
                                class="py-8">
                                <div :class="[isActive ? 'text-electric-violet-600' : 'text-electric-violet-600/30']" class="transition">
                                    <div class="font-display text-2xl font-medium leading-snug">
                                        {{ item.title }}
                                    </div>

                                    <div class="mt-4 text-base opacity-75">
                                        {{ item.content }}
                                    </div>
                                </div>
                            </ScrollTrigger>
                        </ScrollTriggerGroup>

                        <ScrollReactorGroup class="sticky top-0 sm:flex items-center justify-center hidden">
                            <ScrollReactor
                                v-for="(item, index) in return_items"
                                :key="index"
                                v-slot="{ show, faded }"
                                class="absolute inset-0 flex items-center justify-center">
                                <component :is="item.component" :faded="faded" :show="show"/>
                            </ScrollReactor>
                        </ScrollReactorGroup>
                    </ScrollObserver>


                    <div class="bg-electric-violet-600 rounded-2xl mt-12 md:mt-24 px-6 py-16 text-cape-palliser-100 text-center gap-6 flex flex-col">
                        <h3 class="text-3xl">Start shipping with SAMOS</h3>
                        <div class="max-w-xl mx-auto py-3">
                            <p class="font-light">Request a demo to learn more about how SAMOS can help you ship directly to your customers.</p>
                        </div>
                        <div class="flex gap-6 justify-center flex-col items-center sm:flex-row">
                            <SamosSpotlightBtn as="button" class="" @click="openContactUsDialogueFn()">
                                Talk to us
                            </SamosSpotlightBtn>

                            <Link
                                class="uppercase group relative inline-flex items-center overflow-hidden rounded-full bg-white px-8 py-3 transition shadow shadow-electric-violet-500 outline-electric-violet-500 border border-electric-violet-500 text-electric-violet-600"
                                href="/start-shipping">
                                Start shipping
                            </Link>


                        </div>
                    </div>


                </div>

            </div>


        </div>

    </section>

    <section>
        <div class="flex flex-col max-w-7xl items-center justify-between xl:px-8 xl:mx-auto">
            <div class="bg-cape-palliser-100 border border-t-0 border-electric-violet-500 w-full">
                <div
                    class="bg-cape-palliser-500 text-white w-full rounded-t-5xl pt-16 pb-28 px-6 xl:px-12 border border-x-0 border-electric-violet-500">
                    <h3 class="text-center text-4xl">From the blog</h3>

                    <div
                        class="mx-auto mt-12 grid grid-cols-1 gap-x-6 xl:gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        <article v-for="article in blog_posts" :key="article.id" class="flex flex-col items-start justify-between">
                            <Link
                                :href="article.category ? '/blog/' + article.category.slug + '/' + article.slug : '/blog/' + article.slug"
                                class="relative w-full">
                                <picture v-if="article.list_image">
                                    <source :srcset="article.list_image.srcset_webp" type="image/webp">
                                    <source :srcset="article.list_image.srcset" :type="article.list_image.mime_type">
                                    <img :alt="article.list_image.alt ? article.list_image.alt : article.title"
                                         :src="article.list_image.url"
                                         class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                         loading="lazy"
                                         onload="if(this.dataset.sized===undefined){this.sizes=Math.ceil(this.getBoundingClientRect().width/window.innerWidth*100)+'vw';this.dataset.sized=''}"
                                         sizes="1px">
                                </picture>
                                <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
                            </Link>
                            <div class="max-w-xl">
                                <div class="mt-8 flex items-center gap-x-4 text-xs">
                                    <time :datetime="article.published_at" class="text-white">{{ article.human_readable_published_at }}
                                    </time>
                                    <Link v-if="article.category"
                                          :href="'/blog/' + article.category.slug"
                                          class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100 capitalize">{{ article.category.title }}
                                    </Link>
                                </div>
                                <div class="group relative">
                                    <h3 class="mt-3 text-lg font-semibold leading-6 text-white">
                                        <Link
                                            :href="article.category ? '/blog/' + article.category.slug + '/' + article.slug : '/blog/' + article.slug">
                                            <span class="absolute inset-0"></span>
                                            {{ article.title }}
                                        </Link>
                                    </h3>
                                    <p class="mt-5 line-clamp-3 text-sm leading-6 text-white">{{ article.excerpt }}</p>
                                </div>
                                <div v-if="article.user" class="relative mt-8 flex items-center gap-x-4">
                                    <picture v-if="article.user.avatar">
                                        <source :srcset="article.user.avatar.srcset_webp" type="image/webp">
                                        <source :srcset="article.user.avatar.srcset" :type="article.user.avatar.mime_type">
                                        <img :alt="'Picture of ' + article.user.name" :src="article.user.avatar.url"
                                             class="h-10 w-10 rounded-full bg-gray-100"
                                             loading="lazy"
                                             onload="if(this.dataset.sized===undefined){this.sizes=Math.ceil(this.getBoundingClientRect().width/window.innerWidth*100)+'vw';this.dataset.sized=''}"
                                             sizes="1px"
                                             src="">
                                    </picture>
                                    <div class="text-sm leading-6">
                                        <p class="font-semibold text-white">
                                            <span class="absolute inset-0"></span>
                                            {{ article.user.name }}
                                        </p>
                                        <p class="text-white">{{ article.user.position }}</p>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>

                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="flex flex-col max-w-7xl items-center justify-between pt-6 xl:px-8 xl:mx-auto -mt-16">
            <div
                class="bg-electric-violet-200 text-electric-violet-600 w-full rounded-t-5xl border border-electric-violet-500 pt-16 pb-28 px-6 xl:px-12 grid md:grid-cols-2 gap-x-8 gap-y-16">

                <div>
                    <h3 class="text-4xl mb-2">Testimonials</h3>
                    <p>Customer reviews and ratings</p>

                    <div class="mt-8 flex gap-0.5 mb-2">
                        <StarIcon v-for="i in 5" :key="i" class="h-6 w-6"/>
                    </div>
                    <p>5 star reviews on Google</p>
                </div>

                <div v-for="testimonial in testimonials" :key="testimonial.id">
                    <div class="flex gap-0.5">
                        <StarIcon v-for="i in testimonial.rating" :key="i" class="h-6 w-6"/>
                    </div>

                    <blockquote class="mt-2 text-md font-semibold leading-7 tracking-tight sm:text-xl sm:leading-8">
                        <p>“{{ testimonial.testimonial }}”</p>
                    </blockquote>

                    <figure class="mt-5 flex items-center gap-x-6">
                        <picture v-if="testimonial.avatar">
                            <source :srcset="testimonial.avatar.srcset_webp" type="image/webp">
                            <source :srcset="testimonial.avatar.srcset" :type="testimonial.avatar.mime_type">
                            <img :alt="'Avatar of ' + testimonial.name" :src="testimonial.avatar.url"
                                 class="h-12 w-12 rounded-full bg-gray-50" loading="lazy"
                                 onload="if(this.dataset.sized===undefined){this.sizes=Math.ceil(this.getBoundingClientRect().width/window.innerWidth*100)+'vw';this.dataset.sized=''}"
                                 sizes="1px">
                        </picture>

                        <div class="text-sm leading-6">
                            <div class="font-semibold">{{ testimonial.name }}</div>
                            <div class="mt-0.5">{{ testimonial.job_title }} at {{ testimonial.company }}</div>
                        </div>
                    </figure>
                </div>

                <div class="col-span-1 col-start-1">
                    <h3 class="text-3xl font-bold tracking-tight text-electric-violet-700 sm:text-4xl">Don't just take our word for it</h3>
                    <p class="mt-3">
                        <Link href="/customer-stories">Read more of our customer stores</Link>
                        to see how SAMOS has helped businesses like yours grow and succeed.
                    </p>
                    <Link
                        class="inline-block mt-3 rounded-md bg-electric-violet-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-electric-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-violet-600"
                        href="/customer-stories">Customer Stories
                    </Link>
                </div>


            </div>

        </div>
    </section>


    <section>
        <div class="flex flex-col max-w-7xl items-center justify-between pt-6 xl:px-8 xl:mx-auto -mt-16">
            <div
                class="bg-pot-pourri-100 text-electric-violet-600 w-full rounded-t-5xl border border-b-0 border-electric-violet-500 pt-16 pb-28 px-6 xl:px-12">
                <div class="max-w-md">
                    <h3 class="text-4xl mb-2">Contact us</h3>
                    <p>Get in touch to discuss partnering with SAMOS or regarding media or legal queries and we'll get back to you as soon as we can.</p>

                    <p class="mt-8">Partnership, press or legal queries</p>
                    <p class="mt-3">E: <a class="hover:underline" href="mailto:info@samos-e.com">info@samos-e.com</a></p>
                    <p class="mt-3">T: <a class="hover:underline" href="tel:+442034759330">+44(0) 20 3475 9330</a></p>
                    <p class="mt-3">A: <a class="hover:underline" href="https://goo.gl/maps/dr9G8DghjqCpGFBW9"
                                          target="_blank">51 Eastcheap London, EC3M 1DT, United Kingdom</a></p>
                </div>
            </div>
        </div>
    </section>


</template>
<style scoped>
.samos-drop-shadow {
    filter: drop-shadow(2px 4px 6px rgba(77, 62, 227, 0.4));
}
</style>
