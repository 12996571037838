import '../css/app.css';

import {createApp, h} from 'vue';
import {createInertiaApp, router} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy';
import DefaultLayout from './Layouts/DefaultLayout.vue';

import * as Sentry from "@sentry/vue";


createInertiaApp({
    title: (title) => `${title}`,
    resolve: async (name) => {
        const page = await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'))
        page.default.layout ??= DefaultLayout
        return page;
    },
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})
        Sentry.init({
            app,
            tracesSampleRate: 0.2,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.APP_ENV
        });

        app.use(plugin)
            .use(ZiggyVue)
            .mount(el);


        return app;

    },
    progress: {
        color: '#4B5563',
    },

});

const isServer = typeof document === 'undefined';
router.on('exception', (exception) => {
    if (exception.detail.exception.message.includes('Failed to fetch dynamically imported module')) {
        console.warn('Failed to fetch dynamically imported module. Reloading in 1 second...');
        if (!isServer) {
            window.setTimeout(() => window.location.reload(), 1000);
        }
    }
});

document.addEventListener('DOMContentLoaded', function () {

});



