<template>
    <footer aria-labelledby="footer-heading" class="bg-electric-violet-600 text-white">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="mx-auto max-w-7xl px-6 pb-48 pt-20 sm:pt-24 lg:px-8 lg:pt-32">
            <div class="mb-8">
                <svg class="h-8 w-auto" enable-background="new 0 0 185 40" viewBox="0 0 185 40"
                     xmlns="http://www.w3.org/2000/svg">
                    <g fill="#fff">
                        <path
                            d="m16.8 9.6c-1-2.8-2.9-3.4-4.8-3.4-2.8 0-4.5 1.7-4.5 4 0 2.1 1.8 3.1 3.7 3.7l4.4 1.5c5.8 1.8 7.4 5.5 7.4 8.8 0 5.9-5.1 9.4-11.1 9.4-5.4 0-10.5-2.8-11.9-8.2l5.3-1.3c.7 2.5 3.5 4.3 6.6 4.3s5.3-1.8 5.3-4.2c0-2.1-1.7-3.2-3.9-3.9l-4.1-1.4c-4.9-1.5-7.6-4.2-7.6-8.5.1-5.4 4.1-9.4 10.2-9.4 4.3 0 8.5 1.8 10.3 7.4z"></path>
                        <path
                            d="m43.1 1c4.1 0 8.2 1.4 11.1 5.7v-5h6v31.3h-6v-5c-2.9 4.2-7 5.7-11.1 5.7-9.2 0-15.2-7.4-15.2-16.3s6-16.4 15.2-16.4zm.8 5.3c-6.4 0-10 5-10 10.9s3.6 10.9 10 10.9c6.2 0 10.4-4.6 10.5-10.9-.1-6.2-4.3-10.9-10.5-10.9z"></path>
                        <path
                            d="m89.9 15.3c0-6.6-3-8.9-6.3-8.9s-8.7 2.2-8.7 12.1v14.5h-6v-31.3h6v6.3c1.3-4.6 5.4-7 9.9-7 2.9 0 7.4 1 9.8 6.6 2.5-5.5 6.7-6.6 10.4-6.6 7.2 0 11.9 4.2 11.9 14.3v17.6h-6v-17.6c0-6.6-3-8.9-6.3-8.9-2.9 0-8.4 1.8-8.7 11.3v15.2h-6z"></path>
                        <path
                            d="m178.8 9.6c-1-2.8-2.9-3.4-4.8-3.4-2.8 0-4.5 1.7-4.5 4 0 2.1 1.8 3.1 3.7 3.7l4.4 1.5c5.8 1.8 7.4 5.5 7.4 8.8 0 5.9-5.1 9.4-11.1 9.4-5.4 0-10.4-2.8-11.9-8.2l5.3-1.3c.7 2.5 3.5 4.3 6.6 4.3s5.3-1.8 5.3-4.2c0-2.1-1.7-3.2-3.9-3.9l-4.1-1.4c-4.9-1.5-7.5-4.2-7.5-8.5 0-5.3 4.1-9.3 10.1-9.3 4.3 0 8.5 1.8 10.3 7.4z"></path>
                        <path
                            d="m140.4 0c-9.3 0-16.9 7.7-16.9 17.1v1c0 .2 0 .5.1.7 1.2 13.2 16.8 21.1 16.8 21.1s15.8-8 16.9-21.1c0-.2 0-.5 0-.7 0-.3 0-.7 0-1 0-9.4-7.6-17.1-16.9-17.1zm10.7 17.2c0 6.3-4.8 11.4-10.7 11.4s-10.6-5.1-10.6-11.4c0-6.4 4.8-11.5 10.6-11.5 5.9-.1 10.7 5.1 10.7 11.5z"></path>
                    </g>
                </svg>
            </div>
            <div class="md:grid md:grid-cols-3 md:gap-8">
                <div>
                    <h3 class="text-sm font-semibold leading-6 text-white">Services</h3>
                    <ul class="mt-6 space-y-4" role="list">
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/services/parcel-delivery">Parcel Delivery
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/services/parcel-returns">Parcel Returns
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/services/integrations">All Integrations
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/services/parcel-tracking">Parcel Tracking
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/services/parcel-delivery/europe">Shipping to the <abbr title="European Union">EU</abbr>
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/blog/eu-shipping/problems-shipping-cosmetics-and-fragrances-across-the-eu-your-solution">Shipping fragrances & cosmetics to the <abbr title="European Union">EU</abbr>
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/blog/ioss">Learn about <abbr title="International One Stop Shop">IOSS</abbr> for <abbr title="European Union">EU</abbr> Shipping
                            </Link>
                        </li>

                    </ul>
                </div>
                <div>
                    <h3 class="text-sm font-semibold leading-6 text-white">Integrations</h3>
                    <ul class="mt-6 space-y-4" role="list">
                        <li v-for="integration in footer_menu.integrations" :key="integration.id">
                            <Link :href="'/services/integrations/' + integration.slug"
                                  class="text-sm leading-6 text-white hover:underline hover:text-gray-200">{{ integration.name }}
                            </Link>
                        </li>
                    </ul>
                </div>
                <div class="mt-10 md:mt-0">
                    <h3 class="text-sm font-semibold leading-6 text-white">Company</h3>
                    <ul class="mt-6 space-y-4" role="list">
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/about-samos">About SAMOS
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/customer-stories">Customer Stories
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/pricing/samos-shipping-rates">Shipping Rates
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/request-a-demo">Request a Demo
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/resources/sustainability">Sustainability
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/contact-us">Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>
                <div class="mt-10 md:mt-0">
                    <h3 class="text-sm font-semibold leading-6 text-white">Terms &amp; Conditions</h3>
                    <ul class="mt-6 space-y-4" role="list">
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/website-terms-and-conditions">Website T&Cs
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/privacy-and-cookie-policy"
                                  rel="privacy-policy">Privacy & Cookies
                            </Link>
                        </li>
                        <li>
                            <Link class="text-sm leading-6 text-white hover:underline hover:text-gray-200"
                                  href="/shipping-terms-and-conditions"
                                  rel="terms-of-service">Shipping T&Cs
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="mt-16 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-24">
                <div class="flex space-x-6 md:order-2">
                    <a class="text-white hover:text-gray-200" href="https://www.facebook.com/samos.ecom/" target="_blank">
                        <span class="sr-only">Facebook</span>
                        <svg aria-hidden="true" class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                            <path clip-rule="evenodd"
                                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                  fill-rule="evenodd"/>
                        </svg>
                    </a>
                    <a class="text-white hover:text-gray-200" href="https://www.linkedin.com/company/samos-e-commerce/" target="_blank">
                        <span class="sr-only">LinkedIn</span>
                        <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="m17 17h-3v-4.6c0-1.1 0-2.5-1.5-2.5s-1.8 1.2-1.8 2.5v4.6h-3v-9.5h2.8v1.3c.6-1 1.7-1.6 2.8-1.5 3 0 3.6 2 3.6 4.5zm-12.6-10.8c-.9 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7 1.7.8 1.7 1.7c.1.9-.7 1.7-1.7 1.7m1.5 10.8h-2.9v-9.5h3zm12.6-17h-17c-.8 0-1.5.6-1.5 1.4v17.1c0 .9.7 1.5 1.5 1.5h17c.8 0 1.5-.6 1.5-1.4v-17.2c0-.8-.7-1.4-1.5-1.4"/>
                        </svg>
                    </a>
                    <a class="text-white hover:text-gray-200" href="https://www.instagram.com/samosecommerce/?hl=en"
                       target="_blank">
                        <span class="sr-only">Instagram</span>
                        <svg aria-hidden="true" class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                            <path clip-rule="evenodd"
                                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                  fill-rule="evenodd"/>
                        </svg>
                    </a>
                    <a class="text-white hover:text-gray-200" href="https://youtube.com/@samose-commerce4997?si=rqZ4UmQ6V1we6Kuk"
                       target="_blank">
                        <span class="sr-only">YouTube</span>
                        <svg aria-hidden="true" class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                            <path clip-rule="evenodd"
                                  d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                                  fill-rule="evenodd"/>
                        </svg>
                    </a>
                </div>
                <p class="mt-8 text-xs leading-5 text-gray-100 md:order-1 md:mt-0">&copy; {{ year }} SAMOS e-commerce LTD</p>
            </div>
        </div>
    </footer>
</template>
<script setup>
import {Link, usePage} from '@inertiajs/vue3'
import {computed} from "vue";

const page = usePage();
const footer_menu = computed(() => page.props.footer_menu)
const year = new Date().getFullYear();
</script>
